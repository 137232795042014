<template>
  <div class="detail">
    <h1>{{ detail.title }}</h1>

    <img class="cover" :src="detail.cover" alt="">

    <template v-if="challenges.length > 0">
        <t-title class="title" title="Challenges"></t-title>
        <!-- <img class="cover" :src="challenges.imageUrl" alt=""/> -->
        <div class="challenge" v-for="(item, index) in challenges" :key="index">
            <div class="index">{{ index + 1 }}</div>
            <div class="content">
                <p class="title">{{ item.describe }}</p>
                <p style="white-space: pre-line;">{{ item.content }}</p>
            </div>
        </div>
    </template>

    <template v-if="solution">
        <t-title class="title" title="Solution"></t-title>
        <img class="cover" :src="solution.imageUrl" alt="">
    </template>

    <template v-if="architecture">
        <t-title class="title" title="Solution Architecture"></t-title>
        <img class="cover" :src="architecture.imageUrl" alt=""/>
    </template>
    
    <template v-if="application.length > 0">
        <t-title class="title" title="AI Application"></t-title>
        <div class="application">
            <div class="item" v-for="(item, index) in application" :key="index + 'b'">
                <div>
                    <img :src="item.imageUrl" alt="">
                </div>
                <p>{{ item.describe }}</p>
            </div>
        </div>
    </template>

    <template v-if="benefits.length > 0">
        <t-title class="title" title="Customer Benefits"></t-title>
        <div class="benefits" v-for="(item, index) in benefits" :key="index + 'c'">
            <div class="top-pc">
                <div class="top">
                    <div class="left">
                        <img :src="item.imageUrl" alt="">
                    </div>
                    <div class="right">
                        <div class="title">{{ item.title }}</div>
                        <div class="content">
                            <p class="label">Solution:</p>
                            <ul>
                                <template v-for="item in benefitValues[item.id]">
                                    <li v-if="item.type === 'Solution'" :key="item.id"><p>{{ item.content }}</p></li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="top-m">
                <div class="title">{{ item.title }}</div>
                <img :src="item.imageUrl" alt=""/>
                <div class="content">
                    <p class="label">Solution:</p>
                    <ul>
                        <template v-for="item in benefitValues[item.id]">
                            <li v-if="item.type === 'Solution'" :key="item.id + 'b'"><p>{{ item.content }}</p></li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="bottom">
                <p class="label">Value:</p>
                <ul>
                    <template v-for="item in benefitValues[item.id]">
                        <li v-if="item.type === 'Value'" :key="item.id"><p>{{ item.content }}</p></li>
                    </template>
                </ul>
            </div>
        </div>
    </template>
    <!-- <div class="QR-code">
        <img src="@/assets/image/solution/qr2.png" alt="">
    </div> -->
    <template v-if="docs.length > 0">
        <!-- <t-title class="title" title="Documentation"></t-title> -->
        <!-- <ul class="docs">
            <li v-for="(item, index) in docs" :key="item.id">
                <div class="doc">
                    <div style="width: 40px; flex-shrink: 0;">
                        <div class="index">{{ index + 1 }}</div>
                    </div>
                    <div class="doc-item">{{ item.docName }}</div>
                    <div class="down-btn"><i class="el-icon-download"></i></div>
                </div>
            </li>
        </ul> -->
        <div class="section-title">Documentation</div>
        <div class="documents">
            <div class="document" v-for="(item) in docs" :key="item.id">
                <div class="resouse-name">{{ item.docName }}</div>
                <div class="resouse-link" @click="download(item.docUrl)">{{ $i18n.t('productDetail.clicktoLoad' )}}</div>
            </div>
        </div>
    </template>
  </div>
</template>

<script>
import {getDetails} from '@/api/solution';
import title from './components/title.vue';
export default {
name: 'SolutionDetails',
components: {
    't-title': title
},
data() {
    return {
        id: '',
        content: null,
        detail: {
            cover: ''
        },
        challenges: [],
        solution: null,
        architecture: null,
        application: [],
        benefits: [],
        benefitValues: {},
        docs: []
    }
},
mounted() {
    this.id = this.$route.params.id;
    this.getData(this.id)
},
methods: {
    download(value) {
        window.open(value)
    },
    async getData(id) {
        const {data: {data, isSuccess}} = await getDetails(id)
        if (isSuccess) {
            // this.content = data.content;
            this.detail = data.detail;
            data.graphic.forEach(item => {
                if (item.type === 'Challenges') {
                    this.challenges.push(item);
                }
                if (item.type === 'Solution') {
                    this.solution = item;
                }
                if (item.type === 'Solution Architecture') {
                    this.architecture = item;
                }
                if (item.type === 'AI Application') {
                    this.application.push(item);
                }
            });
            this.benefits = data.benefits;
            this.benefitValues = data.value;
            this.docs = data.docs;
        }
    }
},
}
</script>

<style lang="less" scoped>
.detail {
    width: 1080px;
    margin: auto;
    h1 {
        font-weight: 600;
        text-align: center;
        font-size: 40px;
        padding-top: 40px;
        padding-bottom: 60px;
    }
    .cover {
        width: 100%;
    }
    // .title {
    //     margin: auto;
    // }
    .challenge {
        display: flex;
        width: 80%;
        margin: 0 auto;
        .index {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background-color: gray;
            line-height: 20px;
            text-align: center;
            margin-right: 10px;
            font-weight: 600;
            color: #fff;
            flex-shrink: 0;
        }
        .content {
            font-size: 17px;
            .title {
                height: 20px;
                line-height: 20px;
                font-weight: 600;
                margin-bottom: 6px;
            }
            margin-bottom: 10px;
        }
    }
    .application {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        .item {
            width: 300px;
            padding: 10px 6px;
            img {
                width: 100%;
                height: 200px;
            }
            p {
                margin-top: 5px;
                text-align: center;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
    .benefits {
        .top {
            display: flex;
            margin-bottom: 20px;
            .left {
                flex-shrink: 0;
                width: 50%;
                padding-right: 20px;
                img {
                    width: 100%;
                }
            }
            .right {
                width: 50%;
                .title {
                    font-weight: bold;
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                .content {
                    .label {
                        font-weight: bold;
                        font-size: 16px;
                        color: #000;
                        padding: 0;
                    }
                    ul {
                        padding-left: 20px;
                        li {
                            list-style-type: disc;
                            color: #d2333c;
                            p {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
        .top-pc {
            display: block;
        }
        .top-m {
            display: none;
        }
        .bottom {
            padding-bottom: 60px;
            .label {
                font-weight: bold;
                font-size: 20px;
                color: #000;
                padding: 0;
            }
            ul {
                padding-left: 20px;
                li {
                    list-style-type: disc;
                    color: #d2333c;
                    p {
                        color: #000;
                    }
                }
            }
        }
    }
    .QR-code {
        padding: 30px;
        text-align: center;
        img {
            width: 220px;
            height: 240px;
        }
    }
    .docs {
        width: 60%;
        margin: auto;
        padding-bottom: 30px;
        li+li {
            margin-top: 10px;
        }
        .doc {
            display: flex;
            align-items: center;
            height: 40px;
            background-color: #f0f0f040;
            border-radius: 6px;
            // padding-right: 20px;
            .index {
                width: 20px;
                height: 20px;
                background-color: #d2333c;
                color: #fff;
                text-align: center;
                line-height: 20px;
                border-radius: 50%;
                margin: auto;
            }
            .doc-item {
                min-width: calc(100% - 40px - 30px);
                font-weight: 500;
                font-size: 18px;
                padding: 0 10px;
            }
            .down-btn {
                width: 26px;
                font-size: 18px;
                flex-shrink: 0;
                cursor: pointer;
                [class^=el-icon-] {
                    font-weight: bold !important;
                }
            }
        }
    }
    .section-title {
        height: 40px;
        line-height: 40px;
        font-size: 30px;
        color: #000;
        border-left: 6px solid #c7000b;
        padding-left: 32px;
        margin-bottom: 30px;
        margin-top: 60px;
    }
    .documents {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .document {
            width: 100%;
            height: 40px;
            padding-left: 40px;
            // padding-right: 60px;
            position: relative;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            &::before {
                position: absolute;
                left: 0px;
                top: 15px;
                content: '';
                width: 10px;
                height: 10px;
                background-color: gray;
                border-radius: 50%;
            }
            margin-bottom: 20px;
            .resouse-name {
                font-size: 16px;
                width: 500px;
            }
            .resouse-link {
                cursor: pointer;
                border: 1px solid #c7000b;
                padding: 5px 11px 5px 11px;
                border-radius: 224px;
                color: #c7000b;
                font-size: 16px;
            }
        }
    }
}
@media screen and (max-width: 991px){
    .detail {
        width: 100%;
    }
    .top-pc {
        display: none !important;
    }
    .top-m {
        display: block !important;
        .title {
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            padding: 20px;
        }
        img {
            width: 100%;
        }
        .content {
            padding: 20px;
            .label {
                font-weight: bold;
                font-size: 20px;
                color: #000;
                padding: 0;
            }
            ul {
                padding-left: 20px;
                li {
                    list-style-type: disc;
                    color: #d2333c;
                    p {
                        color: #000;
                    }
                }
            }
        }
    }
    .bottom {
        padding: 0 20px 20px;
    }
}
</style>