import axiosApi from '@/api/axiosApi';

// 获取所有solution的过滤器
export const getAllFilter = () => {
  return axiosApi({
    method: 'get',
    url: '/intserv/solution/getAllFilter'
  })
}

export const getSolutions = (data) => {
  return axiosApi({
    method: 'post',
    url: '/intserv/solution/getSolutions',
    data
  })
}

export const getSolution = (data) => {
  return axiosApi({
    method: 'post',
    url: '/intserv/solution/getSolution',
    data
  })
}


export const getSolutionsByTitle = (data) => {
  return axiosApi({
    method: 'post',
    url: '/intserv/solution/getSolutionsByTitle',
    data
  })
}

export const getDetails = (id) => {
  return axiosApi({
    method: 'get',
    url: `/intserv/solution/getDetails/${id}`,
  })
}