<template>
  <div class="t-title">
    <div class="left-box">
        <div class="box b1"></div>
    </div>
    <div class="content">{{ title }}</div>
    <div class="right-box">
        <div class="box b2"></div>
        <div class="box b3"></div>
    </div>
</div>
</template>

<script>
export default {
name: 'SolutionTitle',
props: {
    title: String
},
data() {
    return {

    }
}
}
</script>

<style scoped>
.content {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    z-index: 3;
    background-color: #c7000b;
    width: 150px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    display: inline;
    padding: 4px 20px;
}

.t-title {
    height: 90px;
    position: relative;
    padding-top: 27.5px;
    padding-bottom: 27.5px;
    padding-left: 17px;
    padding-right: 17px;
    box-sizing: border-box;
    display: table;
    margin: 40px auto;
}

.left-box {
    position: absolute;
    bottom: 24px;
    left: 8px;
    width: 50%;
    height: 30px;
    border: 0.5px solid #c7000b;
    z-index: -1;
}
.right-box {
    position: absolute;
    top: 20px;
    right: 8px;
    width: 40%;
    height: 30px;
    border: 0.5px dotted #c7000b;
    z-index: -1;
}
.box {
    width: 4px;
    height: 4px;
    background-color: #c7000b;
    position: absolute;
}
.b1 {
    top: 13px;
    left: -2px;
}
.b2 {
    top: -2px;
    left: -2px;
}
.b3 {
    right: -2px;
    bottom: -2px;
}
</style>